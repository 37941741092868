import { render, staticRenderFns } from "./construction.vue?vue&type=template&id=398083e0&scoped=true"
import script from "./construction.vue?vue&type=script&lang=js"
export * from "./construction.vue?vue&type=script&lang=js"
import style0 from "./construction.vue?vue&type=style&index=0&id=398083e0&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "398083e0",
  null
  
)

export default component.exports